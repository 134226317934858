<template>
  <div class="subject-color">
    <div class="picker-box">
      <span style="margin-right: 60px">主题色调：{{ subjectColor }}</span>
      <el-button type="text" style="margin-right: 5px" @click="subjectColor='#FFFFFF00'">重置</el-button>
      <el-color-picker
        v-model="subjectColor"
        popper-class="set-subject-color"
        size="small"
      />
    </div>
    <span style="font-size: 12px; color: #b6b4b4">提示：主题色为智能套餐卡商城界面的按钮颜色</span>
    <div style="margin-top: 20px">
      <el-button type="primary" size="mini" @click="saveColor">保存</el-button>
    </div>
  </div>
</template>

<script>
import {setSubjectColor, getSubjectColor} from '../../api/floor'

export default {
  name: "subject-color",
  data() {
    return {
      subjectColor: null
    }
  },
  methods: {
    saveColor() {
      const card_colour = this.subjectColor === '#FFFFFF00' ? null : this.subjectColor
      setSubjectColor({card_colour}).then(res => {
        this.$message.success('保存成功')
      })
    },
    getColor() {
      getSubjectColor().then(res => {
        this.subjectColor = res.card_colour ? res.card_colour : '#FFFFFF00'
      })
    }
  },
  created() {
    this.getColor()
  }
}
</script>

<style lang="scss" scoped>
.subject-color {
  padding: 20px;
  background: #FFF;
  border-radius: 5px;

  .picker-box {
    margin-bottom: 10px;
    display: flex;
    align-items: center;

    /deep/ .el-color-picker__trigger {
      width: 88px;
    }
  }
}
</style>
